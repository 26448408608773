html {
    height: 100%;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: 50px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1c2025;
    background-image: -webkit-radial-gradient(50% 0, ellipse farthest-corner, #303740 30%, #1c2025 100%);
    background-repeat: no-repeat scroll;
    background-attachment: fixed;
    background-size: 100% 100vh;
}

.error {
    font-size: 22px;
    color: #fff;
}

header {
    width: 100%;
    background-color: #13161a;
    margin: 0px;
    padding: 0;
    overflow: auto;
}

h1 {
    padding: 0px;
    margin: 0px;
}

h1 a {
    display: block;
    padding: 0px;
    background: transparent url("../public/images/logo_n.svg") no-repeat scroll center center;
    background-size: 100%;
    width: 150px;
    text-indent: -3333px;
    border: 0;
    margin: 10px;
}

@media only screen and (max-width: 600px) {
    main {
        margin-top: 0;
    }

    .galleryDisplay {
        grid-template-columns: repeat(1, 1fr);
    }

    .showCase .showCaseItem h2 {
        position: absolute;
        bottom: 2px;
        padding: 5px 10px;
        font-family: Oswald, sans-serif;
        font-weight: 400;
        color: #fff;
        background-color: rgba(28, 32, 37, 0.7);
        z-index: 99;
        font-size: 14px;
    }

    .aboutbox {
        padding: 15px;
        padding-bottom: 0;
        width: 80%;
    }
}

@media only screen and (min-width: 600px) {
    main {
        margin-top: 50px;

    }

    .galleryDisplay {
        grid-template-columns: repeat(3, 1fr);

    }

    .showCase .showCaseItem h2 {
        position: absolute;
        bottom: 10px;
        padding: 10px 30px;
        font-family: Oswald, sans-serif;
        font-weight: 400;
        color: #fff;
        background-color: rgba(28, 32, 37, 0.7);
        z-index: 99;
    }

    .aboutbox {
        padding: 40px;
        width: 60%;
    }
}

@media only screen and (min-width: 1300px) {
    main {
        margin: 0 auto;
        margin-top: 50px;
        width: 1200px;
    }

    .galleryDisplay {
        grid-template-columns: repeat(3, 1fr);
    }

    .showCase {
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
    }

    .showCase .showCaseItem h2 {
        position: absolute;
        bottom: 10px;
        padding: 10px 30px;
        font-family: Oswald, sans-serif;
        font-weight: 400;
        color: #fff;
        background-color: rgba(28, 32, 37, 0.7);
        z-index: 99;
    }

    .aboutbox {
        padding: 40px;
        width: 60%;
    }
}

.showCase {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    column-gap: 0;
    row-gap: 0;
}

.showCase .showCaseItem {
    position: relative;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0%;
    padding-bottom: 66.67%;
    grid-column: auto / span 2;
    transition: opacity .3s ease-in;
    opacity: .7;
}

.showCase .showCaseItem a {
    width: 100%;
    position: absolute;
}

.showCase div:hover {
    opacity: 1;
}

.showCase .showCaseItem:nth-child(1) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
    grid-column: 1 / span 6;
}

.showCase .showCaseItem:nth-child(2),
.showCase .showCaseItem:nth-child(3) {
    clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
    grid-column: auto / span 3;
}

.showCase .showCaseItem:nth-child(2) {
    top: -10%;
}

.showCase .showCaseItem:nth-child(3) {
    top: -20%;
}

.gallery {
    width: 90%;
    margin: 0 auto;
    margin-top: 70px;
    font-family: Oswald, sans-serif;
    font-weight: 400;
    color: #ccc;
}

.gallery h2 {
    border-bottom: 1px solid #ccc;
}

.galleryDisplay {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: grid;
    column-gap: 5px;
    row-gap: 5px;
}

.galleryDisplay div img {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}

.galleryDisplay div:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
}

.galleryDisplay div:nth-child(3) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-end: 3;
}

.galleryDisplay div:nth-child(7) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-end: 5;
}

.aboutbox {
    font-family: Oswald, sans-serif;
    font-weight: 300;
    color: #ccc;
    margin: 30px auto;
    font-size: 18px;
}

.aboutbox h3 {
    font-size: 24px;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #13161a;
    color: #ccc;
    font-size: smaller;
    padding: 5px;
    font-family: 'Oswald', sans-serif;
    border-bottom: 4px solid #ff9900;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}